<template lang="pug">
div
  Navbar(:app-name="$route.meta.app_name")
  Notifications
  router-view
</template>

<script>
import AdBlockService from '@master/Services/AdBlockService';

import Navbar from '@master/UI/Navbar/Navbar.vue';
import Notifications from '@master/UI/Notifications.vue';

export default {
  components: {
    Navbar,
    Notifications,
  },

  created() {
    this.$user._init();
    AdBlockService.validate();
  },
};
</script>
